import type { TaxRate } from '~/models/TaxRate';

export type TaxRateState = {
    taxRate: TaxRate | null;
    taxRates: TaxRate[];
}

export const defaultStateData = (): TaxRateState => ({
    taxRate: null,
    taxRates: [],
});

export const DefaultState = (): TaxRateState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
