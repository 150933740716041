import { SET_TAX_RATE, SET_TAX_RATES } from './-mutation-types';
import { useTaxRatesStore } from '~/stores/tax-rates';
import TaxRateService from '~/services/api/TaxRateService';
import type { TaxRate } from '~/models/TaxRate';

const Actions = {
    async fetchTaxRate(taxRateId: number) {
        const taxRatesStore = useTaxRatesStore();
        const taxRateService = new TaxRateService();
        const response = await taxRateService.fetchTaxRate(taxRateId);

        taxRatesStore[SET_TAX_RATE](response);
    },
    setTaxRate(taxRate: TaxRate) {
        const taxRatesStore = useTaxRatesStore();
        taxRatesStore[SET_TAX_RATE](taxRate);
    },
    setTaxRates(taxRates: TaxRate[]) {
        const taxRatesStore = useTaxRatesStore();
        taxRatesStore[SET_TAX_RATES](taxRates);
    },
};

export default Actions;
