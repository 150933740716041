import { GET_TAX_RATE, GET_TAX_RATES, GET_TAX_RATE_BY_ID } from './-getter-types';
import { useTaxRatesStore } from '~/stores/tax-rates';
import type { TaxRate } from '~/models/TaxRate';

const Getters = {
    [GET_TAX_RATE]: (): TaxRate | null => {
        const taxRatesStore = useTaxRatesStore();

        return taxRatesStore.taxRate;
    },
    // eslint-disable-next-line unicorn/consistent-function-scoping
    [GET_TAX_RATE_BY_ID]: () => (taxRateId: number): TaxRate | null => {
        const taxRatesStore = useTaxRatesStore();

        return taxRatesStore.taxRates.find(taxRate => taxRate.id === taxRateId) ?? null;
    },
    [GET_TAX_RATES]: (): TaxRate[] => {
        const taxRatesStore = useTaxRatesStore();

        return taxRatesStore.taxRates;
    },
};

export default Getters;
