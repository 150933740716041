import { SET_TAX_RATE, SET_TAX_RATES } from './-mutation-types';
import { useTaxRatesStore } from '~/stores/tax-rates';
import type { TaxRate } from '~/models/TaxRate';

const Mutations = {
    [SET_TAX_RATE](payload: TaxRate | null) {
        const taxRatesStore = useTaxRatesStore();
        taxRatesStore.taxRate = payload;
    },
    [SET_TAX_RATES](payload: TaxRate[] | []) {
        const taxRatesStore = useTaxRatesStore();
        taxRatesStore.taxRates = payload;
    },
};

export default Mutations;
