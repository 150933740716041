/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './tax-rates/state';
import Getters from './tax-rates/getters';
import Actions from './tax-rates/actions';
import Mutations from './tax-rates/mutations';

export const useTaxRatesStore = defineStore(
    'tax-rates',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
